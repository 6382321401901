import KastAppClient, { DeviceCodeTokenStrategy, OAuth2DeviceOutputDTO, OAUTH2_TOKEN_ERROR_TYPE, Tokens } from "@kalyzee/kast-app-module";
import { useEffect, useRef, useState } from "react";
import QRCode from 'qrcode';
import { getOAuthBaseUrl } from "../helpers/api";
import { TokenErrorToMessage } from "../helpers/error";
import { useTranslation } from "react-i18next";

interface DeviceCodeProps {
    clientId: string,
    client: KastAppClient,
    onTokens?: (tokens: Tokens) => void,
}
  
export const DeviceCode = ({ 
    clientId, 
    client,
    onTokens,
}: DeviceCodeProps) => {
    const stepMs = 50;

    const [error, setError] = useState<string|undefined>();
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const [progress, setProgress] = useState(0);
    const [totalTimeout, setTotalTimeout] = useState(0);
    const [codes, setCodes] = useState<OAuth2DeviceOutputDTO|undefined>();
    const [tokens, setTokens] = useState<Tokens|undefined>();

    const { t } = useTranslation();

    const timeoutMs = (expiration: Date) => {
        return Math.max(new Date(expiration).valueOf() - new Date().valueOf(), 0);
    }

    const initStrategy = async () => {
        const strategy = new DeviceCodeTokenStrategy(clientId, (c: OAuth2DeviceOutputDTO) => {
            setCodes(c);
            setTotalTimeout(timeoutMs(c.expires_at));
        });
        try {
            const tokens = await client.connect(strategy);
            if (!tokens) return;
            setTokens(tokens);
            setTimeout(() => {
                onTokens?.(tokens);
            }, 1000); // Just so we can see the "Success!" for one second ...
        } catch (error: any) {
            setError(error.type ? TokenErrorToMessage[error.type as OAUTH2_TOKEN_ERROR_TYPE] : error.message);
        }
    }

    const reset = () => {
        setCodes(undefined);
        setTokens(undefined);
        setError(undefined);
        initStrategy();
    }

    useEffect(() => {
        if (!codes?.device_code) return;

        const progressTimer = setInterval(() => {
            const timeout = timeoutMs(codes.expires_at);
            setProgress((totalTimeout - timeout) / totalTimeout);
        }, stepMs);

        return () => clearInterval(progressTimer);
    }, [codes, totalTimeout]);

    useEffect(() => {
        initStrategy();
    }, []);

    useEffect(() => {
        const url = `${getOAuthBaseUrl()}/device?code=${codes?.user_code}`;
        const canvas = canvasRef.current;
        QRCode.toCanvas(
            canvas, 
            url, 
            { color: { light: '#ffffff', dark: '#253144' }, width: 200 }, 
            (error: any) => {
                if (error) console.error(error);
            },
        );
    }, [codes]);

    const renderProgressBar = () => (
        <div style={{
            width: '100%',
            height: 10,
            border: '1px solid white',
            borderRadius: 5,
            justifyContent: 'left',
            overflow: 'hidden',
        }}>
            <div style={{
                backgroundColor: 'white',
                height: '100%',
                width: `${progress*100}%`,
            }}></div>
        </div>
    );

    const renderStatus = () => {
        const renderText = (text: string, error=false) => {
            return <div style={{ fontSize: '1.5em', color: error ? 'red' : '#15ee9c' }}>{text}</div>
        }
        if (error) return renderText(error, true);
        if (tokens) return renderText(`${t('device_paired')} !`);
        return renderProgressBar();
    }

    const renderResetButton = () => {
        return (
            <button 
                onClick={reset}
                style={{ 
                    fontSize: '1.5em', 
                    backgroundColor: 'white', 
                    borderStyle: 'none',
                    borderRadius: 5,
                    padding: 5,
                    paddingLeft: 10,
                    paddingRight: 10,
                    cursor: 'pointer',
                }}
            >{t('reset')}</button>
        );
    };

    return (
        <div style={{ 
            display: 'flex', 
            flexDirection: 'column', 
            backgroundColor: '#253144dd',
            padding: 30,
            borderRadius: 10,
            alignItems: 'center', 
            gap: 20 
        }}>
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                borderRadius: 10,
                padding: 5,
                alignItems: 'center',
                justifyContent: 'center',
                gap: 20,
            }}>
                <div style={{ 
                    color: 'white', 
                    fontSize: '1.5em',
                    whiteSpace: 'nowrap',
                }}>{t('pair_your_device')} :</div>
                <div style={{ color: 'white', fontSize: '2em' }}>{codes?.user_code}</div>
                <canvas ref={canvasRef} width={200} height={200}></canvas>
            </div>
            {renderStatus()}
            {!tokens && renderResetButton()}
        </div>
    );
}