import { FileOutputDTO, FILE_ID_TYPE } from "@kalyzee/kast-app-module";
import client from "./api";

export interface FileData {
    id: string,
    blob: Blob,
}

interface FileRequestResult <T = any> {
    id: string,
    success: boolean,
    data?: T,
}

const generateFileExternalId = (whiteboardId: string, fileId: string): string => `${whiteboardId}.${fileId}`;

const performFileRequest = async <T> (
    id: string,
    makeRequest: () => Promise<T | undefined>,
    onError?: (error: any) => boolean,
): Promise<FileRequestResult<T>> => {
    try {
        const data = await makeRequest();
        if (data) {
            return {
                id,
                success: true,
                data,
            }
        }
    } catch (error) {
        return {
            id,
            success: onError ? onError(error) : false,
        }
    }
    // returning false if no data is returned
    return {
        id,
        success: false,
    }
}
  
const downloadFile = async (whiteboardId: string, id: string): Promise<FileRequestResult<Blob>> => {
    return await performFileRequest(id, async () => {
        const response = await client.file?.download(generateFileExternalId(whiteboardId, id), FILE_ID_TYPE.EXTERNAL);
        if (!response) return undefined;
        return new Blob([response.data]);
    })
}

export const downloadFiles = async (whiteboardId: string, ids: string[]): Promise<{ downloadedFiles: FileData[], erroredIds: string[] }> => {
    const results = await Promise.all(
        ids.map((id) => downloadFile(whiteboardId, id)),
    );
    const downloadedFiles: FileData[] = [];
    const erroredIds: string[] = [];
    results.forEach((result) => {
        if (result.success && result.data) downloadedFiles.push({ id: result.id, blob: result.data });
        else erroredIds.push(result.id);
    });
    return { downloadedFiles, erroredIds };
}

const uploadFile = async (whiteboardId: string, file: FileData): Promise<FileRequestResult> => {
    return await performFileRequest(
        file.id, 
        async () => {
            return await client.file?.upload(file.blob, generateFileExternalId(whiteboardId, file.id));
        },
        // we don't consider it an error if the file exists on the server
        (error) => error.responseData?.name === 'FileResourceExternalIdAlreadyExists',
    );
}

export const uploadFiles = async (whiteboardId: string, files: FileData[]): Promise<{ uploadedIds: string[], erroredIds: string[] }> => {
    const results = await Promise.all(
        files.map((file) => uploadFile(whiteboardId, file)),
    );
    const uploadedIds: string[] = [];
    const erroredIds: string[] = [];
    results.forEach((result) => {
        if (result.success) uploadedIds.push(result.id);
        else erroredIds.push(result.id);
    });
    return { uploadedIds, erroredIds };
}

export const dataURLToFile = (dataURL: string, filename = "") => {
    const dataIndexStart = dataURL.indexOf(",");
    const byteString = atob(dataURL.slice(dataIndexStart + 1));
    const mimeType = dataURL.slice(0, dataIndexStart).split(":")[1].split(";")[0];
  
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new File([ab], filename, { type: mimeType });
};