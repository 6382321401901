import { useEffect, useRef } from "react";
import { CssFunction } from "../helpers/types";
import { mergeStyles } from "../helpers/utils";

const makeErrorMessageStyle: CssFunction = (extraStyle) => mergeStyles({
    color: 'red',
    fontSize: '0.7em',
}, extraStyle);

const makeInputContainerStyle: CssFunction = (extraStyle) => mergeStyles({
    width: '100%',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
}, extraStyle);

const makeInputStyle: CssFunction = (extraStyle) => mergeStyles({
    height: 33,
    minWidth: 0,
    borderRadius: 4,
    paddingLeft: 10,
    paddingRight: 10,
}, extraStyle);

interface CustomButtonProps {
    value: string,
    onChange: (value: string) => void,
    placeholder?: string,
    errorMessage?: string,
    isMobile?: boolean,
    isPassword?: boolean,
    autoFocus?: boolean,
}

export const CustomInput = ({
    value,
    onChange,
    placeholder,
    errorMessage,
    isMobile,
    isPassword,
    autoFocus,
}: CustomButtonProps) => {
    const ref = useRef<HTMLInputElement | null>(null);

    useEffect(() => {
        if (autoFocus) ref.current?.focus();
    }, [autoFocus]);
    
    return (
        <div style={makeInputContainerStyle()}>
            <input 
                ref={ref}
                type={isPassword ? 'password' : 'text'}
                placeholder={placeholder}
                style={makeInputStyle({
                    border: errorMessage ? '1px solid red' : 'none',
                })}
                value={value} 
                onChange={(event) => onChange(event.target.value)} 
            />
            { 
                errorMessage && (
                    <div style={
                        makeErrorMessageStyle(isMobile ? {} : {
                            position: 'absolute',
                            left: 0,
                            top: '105%',
                        })
                    }
                    >{errorMessage}</div>
                ) 
            }
        </div>
    );
}

CustomInput.defaultProps = {
    isMobile: false,
}

export default CustomInput;
