import { createAppAsyncThunk } from '../../common/helpers/utils';

import client from '../../common/helpers/api';
import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';

interface FileData {
    id: string,
    blob: Blob,
}

export interface FilesState {
  files: FileData[],
}

const getFileData = async (id: string): Promise<FileData> => {
    const response = await client.file.download(id);
    return {
        id,
        blob: new Blob([response.data]),
    }
}

export const downloadFiles = createAppAsyncThunk(
  'main/downloadFile',
  async (ids: string[]) => {
    return await Promise.all(
        ids.map(id => getFileData(id))
    );
  }
)

const initialState: FilesState = {
    files: [],
};

export const filesSlice = createSlice({
    name: 'auth',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(downloadFiles.fulfilled, (state, { payload }) => {
                state.files.concat(payload); // TODO : delete doubles
            });
    },
});

export const {} = filesSlice.actions;

export const selectFiles = (state: RootState) => state.files.files;

export default filesSlice.reducer;