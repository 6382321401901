import { ReactComponent as Spinner } from '../../assets/svg/spinner.svg';
import CustomInput from "./CustomInput";
import CustomButton from "./CustomButton";
import { mergeStyles } from "../helpers/utils";
import { CssFunction } from "../helpers/types";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { logout } from "../../features/main/extraActions";
import { selectExternalAuth, selectExternalWhiteboard } from "../../features/main/mainSlice";
import { useTranslation } from "react-i18next";
import { makeBlurStyle, makeOverlayContainerSectionStyle } from "../css/styles";
import { DockableContainer } from "./DockableContainer";
import { logger } from "../helpers/logging";
import { CommunicationAdapterState, CommunicationAdapterStatus } from '@kalyzee/whiteboard-react';

export enum OverlayMode {
    Default,
    Logout,
    Pin,
}

export interface OverlayProps  {
    adapterState: CommunicationAdapterState,
    defaultId: string,
    defaultName: string,
    tempId: string,
    tempName: string,
    forceDisplay: boolean,
    setForceDisplay: (value: boolean) => void,
    setTempId: (value: string) => void,
    setTempName: (value: string) => void,
    onConfirm: () => void,
    targetPinSize: number,
    pin: string,
    setPin: (pin: string) => void,
    pinError: string,
    onRequestPermission?: () => Promise<boolean>,
    overlayMode: OverlayMode,
    setOverlayMode: (value: OverlayMode) => void,
    retryConnection: () => void,
    isMobile?: boolean,
    joinErrorMessage?: string,
    setJoinErrorMessage?: (message: string) => void,
}

const makeWaitingStyle: CssFunction = () => ({
    display: 'flex',
    flexDirection: 'row',
    gap: 10,
    alignItems: 'center',
    fontSize: '1.25em',
})

const makeContentStyle: CssFunction = (extraStyle) => mergeStyles({
    width: '100%',
    display: 'flex',
    gap: 10,
    fontSize: '1.25em',
    justifyContent: 'center',
}, extraStyle);

const makeMenuStyle: CssFunction = () => ({
    display: 'flex',
    flexDirection: 'row-reverse',
    width: '100%',
    justifyContent: 'space-between',
})

export function Overlay({
    adapterState,
    defaultId,
    defaultName,
    tempId,
    tempName,
    forceDisplay,
    setForceDisplay,
    setTempId,
    setTempName,
    onConfirm,
    targetPinSize,
    pin,
    setPin,
    pinError,
    onRequestPermission,
    overlayMode,
    setOverlayMode,
    retryConnection,
    isMobile,
    joinErrorMessage,
    setJoinErrorMessage,
}: OverlayProps) {
    const dispatch = useAppDispatch();

    const externalAuth = useAppSelector(selectExternalAuth);
    const externalWhiteboard = useAppSelector(selectExternalWhiteboard);

    const { t } = useTranslation();

    const fillDefaultParameters = () => {
        setTempId(defaultId);
        setTempName(defaultName);
    };

    const { status, isLoading } = adapterState;

    // the overlayMode / setForceDisplay / shouldDockPages thing is way too complicated ...
    const handleOutsideClick = () => {
        setJoinErrorMessage?.('');
        if (overlayMode === OverlayMode.Logout || overlayMode === OverlayMode.Pin) {
            if (forceDisplay) {
                setOverlayMode(OverlayMode.Default);
            } else {
                setOverlayMode(OverlayMode.Default);
            }
        } else if (overlayMode === OverlayMode.Default) {
            setOverlayMode(OverlayMode.Default);
            setForceDisplay(false);
        }   
    }

    const handleConfirm = async () => {
        if (onRequestPermission) {
            const permissionGranted = await onRequestPermission();
            if (!permissionGranted) return;
        }
        onConfirm();
    }

    const renderPinBodySection = () => {
        return (
            <div style={makeOverlayContainerSectionStyle({ 
                padding: '1.5em', 
                justifyContent: 'center',
                color: 'black',
            })}>
                <div style={makeWaitingStyle()}>
                    <div>{t('admin_pin')} :</div>
                    <div style={{ flex: 1, minWidth: 0 }}>
                        <CustomInput 
                            value={pin} 
                            onChange={setPin} 
                            placeholder={'0'.repeat(targetPinSize)}
                            errorMessage={pinError}
                            autoFocus
                            isPassword
                        />
                    </div>

                </div>
            </div>
        );
    }

    const renderMessageBodySection = (message: string, external=false, error=false) => {
        return (
            <div style={makeOverlayContainerSectionStyle({
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: 10,
                padding: '1.5em',
                color: 'black',
            })}>
                { external && <div style={{ color: 'gray', fontSize: '0.75em' }}><b>{`[ ${t('external_whiteboard')} ]`}</b></div> }
                <div style={makeWaitingStyle()}>
                    <div>{message}</div> 
                    { error ? <CustomButton onClick={retryConnection}>{t('retry')}</CustomButton> : <Spinner /> }
                </div>
            </div>
        );
    };

    const renderLogoutBodySection = () => {
        return (
            <div style={makeOverlayContainerSectionStyle({ 
                padding: '1.5em', 
                justifyContent: 'center',
                color: 'black',
            })}>
                <div style={makeWaitingStyle()}>
                    <div>{t('logout')} :</div>
                    <CustomButton
                        onClick={async () => {
                            if (onRequestPermission) {
                                const permissionGranted = await onRequestPermission();
                                if (!permissionGranted) return;
                            }
                            dispatch(logout());
                            logger.info('user manually confirmed logout');
                        }} 
                    >{t('confirm')}</CustomButton>
                    <CustomButton
                        onClick={() => setOverlayMode(OverlayMode.Default)}
                        color='#FF0000'
                        hoverColor='#de0000'
                    >{t('cancel')}</CustomButton> 
                </div>
            </div>
        );
    }

    const renderJoinBodySection = () => {
        return ( 
            <div style={makeOverlayContainerSectionStyle({ padding: '1.5em' })}>
                <div 
                    style={
                        makeContentStyle({
                            flexDirection: isMobile ? 'column' : 'row',
                            alignItems: isMobile ? 'stretch' : 'flex-start',
                        })
                    }
                >
                    <div style={{ flex: 1, minWidth: 0 }}>
                        <CustomInput 
                            value={tempId} 
                            onChange={setTempId} 
                            placeholder={t('whiteboard_id')}
                            errorMessage={joinErrorMessage}
                            isMobile={isMobile}
                        />
                    </div>
                    <div style={{ flex: 1, minWidth: 0 }}>
                        <CustomInput 
                            value={tempName} 
                            onChange={setTempName} 
                            placeholder={t('name')}
                            isMobile={isMobile}
                        />
                    </div>
                    <CustomButton onClick={handleConfirm}>{t('join')}</CustomButton>
                </div>
            </div>
        );
    }

    const renderBodySection = () => {
        if (overlayMode === OverlayMode.Pin) return renderPinBodySection();
        if (overlayMode === OverlayMode.Logout) return renderLogoutBodySection();
        
        if (isLoading) return renderMessageBodySection(t('joining'), externalWhiteboard);

        if (status === CommunicationAdapterStatus.Joined && !joinErrorMessage) {
            if (overlayMode === OverlayMode.Default) {
                if (!forceDisplay) return null;
            }
        }
        if (status === CommunicationAdapterStatus.Connecting) return renderMessageBodySection(t('connecting'), externalWhiteboard);
        if (status === CommunicationAdapterStatus.ConnectionError) return renderMessageBodySection(t('connection_error'), externalWhiteboard, true);
        // JoinError, Idle or Connected :
        if (externalWhiteboard) return renderMessageBodySection(t('failed_to_join'), true, true);
        return renderJoinBodySection();
    }

    const renderMenuSection = () => {
        return (!externalAuth || defaultId) ? (
            <div style={makeOverlayContainerSectionStyle({ padding: '0.5em' })}>
                <div style={makeMenuStyle()}>
                    {    
                        externalAuth || (
                            <CustomButton
                                onClick={() => setOverlayMode(OverlayMode.Logout)}
                                nativeKey='whiteboard-overlay-btn-logout'
                                color='#FF0000'
                                hoverColor='#de0000'
                            >{t('logout')}</CustomButton>
                        )
                    }
                    {
                        defaultId && (
                            <CustomButton
                                onClick={fillDefaultParameters}
                                nativeKey='whiteboard-overlay-btn-joindefault'
                            >{defaultId + (defaultName ? ' (' + defaultName + ')' : '')}</CustomButton> 
                        )
                    }
                </div>
            </div>
        ) : null;
    }

    const renderBlur = () => {
        return (
            <div style={makeBlurStyle()} />
        );
    };

    if (
        status === CommunicationAdapterStatus.Joined 
        && overlayMode === OverlayMode.Default 
        && !forceDisplay
        && !joinErrorMessage
        && !isLoading
    ) return null;

    return (
        <div style={{
            width: '100%',
            height: '100%',
            position: 'absolute',
            top: 0,
            left: 0,
        }}>
            {renderBlur()}
            <DockableContainer 
                isDocked={false}
                onClickOutside={handleOutsideClick}
            >
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: 10,
                }}>
                    {overlayMode === OverlayMode.Default && renderMenuSection()}
                    {renderBodySection()}
                </div>
            </DockableContainer>
        </div>
    )
}

Overlay.defaultProps = {
    forceDisplay: false,
    isMobile: false,
}

export default Overlay;