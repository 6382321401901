import { PostMessageBridgeIn } from "../../packages/post-message-bridge/api";
import { getParameter, Parameter } from "./parameters";

let postMessageBridge: PostMessageBridgeIn;

export const getPostMessageBridge = (): PostMessageBridgeIn | undefined => {
    if (postMessageBridge) {
        return postMessageBridge;
    }
    const parentOrigin = getParameter(Parameter.ParentOrigin)?.value;
    if (parentOrigin) {
        console.log('creating with origin : ' + parentOrigin)
        postMessageBridge = new PostMessageBridgeIn(
            window, 
            parentOrigin,
        );
    }
    return postMessageBridge;
}