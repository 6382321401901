import { WebSocket } from '@kalyzee/kast-websocket-module';
import KastAppClient, { LOGIN_LEVEL } from '@kalyzee/kast-app-module';
import { getParameter, Parameter } from './parameters';

export enum ApiMode {
  Prod,
  Abo,
  Dro,
}

export let API_MODE = ApiMode.Prod;

const addToPathNameUrl = (url : string, suffixPathName : string) : string => {
    const urlObj = new URL(url);
    if (urlObj.pathname.endsWith('/') && suffixPathName.startsWith('/')) {
        suffixPathName = suffixPathName.replace('/', '');
    }
    urlObj.pathname += suffixPathName;
    return urlObj.href;
};

const getUrl = () => new URL(window.location.href);

// Source: react-boilerplate => https://github.com/react-boilerplate/react-boilerplate  (app/utils/request.js)

/**
 * Build backend url
 *
 * @returns {string} Returns a url as string
 */
const buildBaseUrl = (protocol: string, target: 'api'|'oauth') => {
  const url = getUrl();
  const hostNameSplitted = url.hostname.split('.');
  hostNameSplitted.splice(0, 1);
  const hostNameStr = hostNameSplitted.join('.');
  return `${protocol}//${target}.${hostNameStr}`;
};

/**
 * Get the current backend base (root) url
 *
 * @returns {string} Returns a url as string
 */
const getBaseUrl = (target: 'api'|'oauth') => {
  const url = getUrl();
  return buildBaseUrl(url.protocol, target);
};

export const getApiBaseUrl = () => {
  if (API_MODE === ApiMode.Abo) return 'https://abo.dev.kalyzee.com/606ee9c4bf11b4001c9ac54b';
  if (API_MODE === ApiMode.Dro) return 'https://dro-1.dev.kalyzee.com/606ee9c4bf11b4001c9ac54b/';
  return getBaseUrl('api');
}

export const getOAuthBaseUrl = () => {
  if (API_MODE === ApiMode.Abo) return 'https://abo-2.dev.kalyzee.com';
  return getBaseUrl('oauth');
}

export const getSocketTokenGenerateUrl = () => `${getApiBaseUrl()}/socket/token`;

const getSocketBaseUrl = () => {
  const url = getUrl();
  if (API_MODE === ApiMode.Abo) return 'wss://abo.dev.kalyzee.com/?organization=606ee9c4bf11b4001c9ac54b';
  if (API_MODE === ApiMode.Dro) return 'https://dro-1.dev.kalyzee.com/?organization=606ee9c4bf11b4001c9ac54b';
  // Keep same security level : http -> ws & https -> wss
  return `${buildBaseUrl(url.protocol.replace('http', 'ws'), 'api')}`;
};

export const getUserSocketUrl = () => addToPathNameUrl(getSocketBaseUrl(), '/user');
  
const token = getParameter(Parameter.Token)?.value;
const refreshToken = getParameter(Parameter.RefreshToken)?.value;
const clientTokens = {
    token,
    refreshToken,
}

const client = new KastAppClient(
    getApiBaseUrl(), LOGIN_LEVEL.NORMAL, clientTokens,
);

const socket = new WebSocket({ url: getUserSocketUrl(), timeoutCommand: 300000 });

export {
  socket,
  client,
}

export default client;