import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { resetToast, selectToast, ToastType } from "../../features/main/mainSlice"
import { useProgress } from "../helpers/hooks";

const ToastTypeDataMap: { 
    [key in ToastType]: {
        color: string,
        textColor: string,
        progressBarColor: string,
    } 
} = {
    [ToastType.Error]: {
        color: '#EF9595',
        textColor: 'lightgray',
        progressBarColor: '#bc7878',
    },
    [ToastType.Info]: {
        color: '#dedee1',
        textColor: '#ababaf',
        progressBarColor: '#ababaf',
    },
    [ToastType.Success]: {
        color: '#C5EBAA',
        textColor: 'white',
        progressBarColor: '#a1bc8e',
    }
}

export const Toast = () => {
    const dispatch = useAppDispatch();
    const { 
        message,
        position = 'center',
        type = ToastType.Info,
        progressBar = false,
    } = useAppSelector(selectToast) ?? {};

    const { t } = useTranslation();

    const [endDate, setEndDate] = useState<Date>();
    const { progress } = useProgress(endDate, 50);

    useEffect(() => {
        if (progress >= 1) {
            dispatch(resetToast());
        }
    }, [progress, dispatch]);

    useEffect(() => {
        if (message) {
            setEndDate(new Date(new Date().getTime() + 5000));
        }
    }, [message]);

    const onClick = () => {
        dispatch(resetToast());
    }

    if (!message) return null;

    const bottom = position === 'center' ? '50%' : '10%';

    const typeData = ToastTypeDataMap[type];

    return (
        <div style={{
            zIndex: 5,
            position: 'absolute',
            bottom,
            left: '50%',
            alignItems: 'center',
            transform: 'translate(-50%, -50%)',
            width: 'min(50%, 300px)',
            fontSize: '1.25em',
            backgroundColor: typeData.color,
            borderRadius: '0.5em',
            padding: '1em',
            color: 'white',
            textAlign: 'center',
            cursor: 'pointer',
        }}
            onClick={onClick}
        >
            {message}
            <div style={{
                marginTop: '0.75em',
                color: typeData.textColor,
                fontSize: '0.75em',
                fontWeight: 'bold',
                fontStyle: 'italic',
            }}>({t('click_to_close')})</div>
            {
                progressBar && (
                    <div style={{
                        marginTop: '0.75em',
                        width: '100%',
                        height: '10px',
                        justifyContent: 'left',
                    }}>
                        <div style={{
                            width: `${(progress * 100)}%`,
                            height: '100%',
                            backgroundColor: typeData.progressBarColor,
                        }} />
                    </div>
                )
            }
        </div>
    );
}