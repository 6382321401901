import { PropsWithChildren } from 'react';

import { useOutsideClickRef } from '../helpers/hooks';

export interface DockableContainerProps {
    isDocked: boolean,
    position?: 'center' | 'bottom',
    onClickOutside: () => void,
}

export function DockableContainer({
    isDocked,
    position = 'center',
    onClickOutside,
    children,
}: PropsWithChildren<DockableContainerProps>) {
    const overlayRef = useOutsideClickRef(onClickOutside);
    const bottom = position === 'center' ? '50%' : '10%';
    return (
        <div 
            ref={overlayRef}
            style={{
                position: 'absolute',
                bottom,
                right: isDocked ? 0 : '50%',
                color: 'lightgrey',
                zIndex: 999,
                width: isDocked ? 'min(30%, 300px)' : 'min(80%, 600px)',
                height: 'fit-content',
                userSelect: 'none',
                transform: isDocked ? 'translate(0, -50%)' : 'translate(50%, -50%)',
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            {children}
        </div>
    );
}
