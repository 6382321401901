export enum Parameter {
    Id = 'id',
    Name = 'name',
    Token = 'token',
    RefreshToken = 'refresh_token',
    IsMaster = 'is_master',
    FollowMasters = 'follow_masters',
    AutoJoin = 'autojoin',
    Invisible = 'invisible',
    AdminPin = 'admin_pin',
    ParentOrigin = 'parent_origin',
    TransparentBackgroundEnabled = 'transparent_background_enabled',
    VersionsSeenAt = 'versions_seen_at',
    VersionIsOldAfterDelay = 'version_is_old_after_delay',
    ShowNewVersionsOnFirstUse = 'show_new_versions_on_first_use'
}

export enum ParameterSource {
    Url = 'url',
    LocalStorage = 'local_storage',
}

export interface SourcedParameterValue {
    value: string,
    source: ParameterSource,
    isDefault: boolean,
}

export type SourceFunction = (parameter: string) => string | null;

const SourceMap: { [key in ParameterSource]: SourceFunction } = {
    [ParameterSource.Url]: (param) => {
        const query = new URLSearchParams(window.location.search);
        const queryParam = query.get(param);
        return queryParam;
    },
    [ParameterSource.LocalStorage]: (param) => {
        const storageParam = localStorage.getItem(param);
        return storageParam;
    },
}

/**
 * Returns a parameter after retrieving it from the given sources, 
 * in the order they were given.
 */
export function getParameterFromSources(name: Parameter, sources?: ParameterSource[], isDefault=false): SourcedParameterValue | undefined {
    const _sources = sources ? sources : [ParameterSource.Url, ParameterSource.LocalStorage];
    const _name = `${name}${isDefault ? '_default' : ''}`;

    for (const s of _sources) {
        const sFunc = SourceMap[s];
        const value = sFunc(_name);
        if (value !== null) return { source: s, value, isDefault };
    }

    return undefined;
}

export function getParameter(name: Parameter): SourcedParameterValue | undefined {
    const urlParameter = getParameterFromSources(name, [ParameterSource.Url, ParameterSource.LocalStorage]);
    if (urlParameter) return urlParameter;

    const defaultParameter = getParameterFromSources(name, [ParameterSource.Url], true);
    return defaultParameter ? defaultParameter : undefined;
}

export function saveParameter(name: Parameter, value: string) {
    localStorage.setItem(name, value);  
}

export function removeParameter(name: Parameter) {
    localStorage.removeItem(name);
}