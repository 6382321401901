import { useState } from "react";
import { useAppDispatch } from "../../app/hooks";
import { setToast, ToastType } from "../../features/main/mainSlice";
import { makeOverlayContainerSectionStyle } from "../css/styles";
import CustomButton from "./CustomButton";
import { CustomTextArea } from "./CustomTextArea";
import { DockableContainer } from "./DockableContainer"
import client from '../helpers/api';
import { ReportCreateDTO, REPORT_SCOPE } from "@kalyzee/kast-app-module";
import { useTranslation } from "react-i18next";

import { ReactComponent as Spinner } from '../../assets/svg/spinner.svg'

export interface BugReporterProps {
    display?: boolean,
    setDisplay?: (value: boolean) => void,
    getExtraData?: () => string,
}

export function BugReporter({
    display,
    setDisplay,
    getExtraData,
}: BugReporterProps) {
    const [isSending, setIsSending] = useState(false)
    const [message, setMessage] = useState('');
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const onClickOutside = () => {
        setDisplay?.(false);
        if (message) {
            dispatch(setToast({ message: t('draft_saved'), position: 'bottom' }));
        }
    }

    const onSend = async () => {
        const createDTO: ReportCreateDTO = {
            userMessage: message, 
            scope: REPORT_SCOPE.WHITEBOARD_CLIENT,
        }
        if (getExtraData) {
            createDTO.data = getExtraData();
        }
        try {
            setIsSending(true);
            await client.report.create(createDTO);
        } catch {
            setIsSending(false);
            setDisplay?.(false);
            dispatch(setToast({ message: t('report_could_not_be_sent'), position: 'bottom', type: ToastType.Error } ));
            return;
        }
        setIsSending(false);
        setDisplay?.(false);
        dispatch(setToast({ message: t('report_sent'), position: 'bottom', type: ToastType.Success } ));
        setMessage('');
    }

    if (!display) {
        return null;
    }

    const renderBody = () => {
        return (
            <div style={makeOverlayContainerSectionStyle({
                flexDirection: 'column',
                justifyContent: 'center',
                color: 'black',
                alignItems: 'flex-end',
                padding: '0.5em',
                paddingBottom: '0.5em',
            })}>
                <CustomTextArea
                    maxLength={250}
                    defaultValue={message}
                    onChange={(value) => setMessage(value)}
                    placeholder={t('i_would_like_to_report_a_bug')}
                    autoFocus
                    containerStyle={{ height: '4em' }}
                />
                <div style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-end'
                }}>
                    <CustomButton style={{ 
                        marginTop: '0.5em', 
                        alignItems: 'center',
                    }} onClick={onSend}>
                        {t('send')} 
                        {isSending && (
                            <div style={{ marginLeft: '0.5em', display: 'flex' }}>
                                {<Spinner color='white' />}
                            </div>
                        )}
                    </CustomButton>
                </div>
            </div>
        )
    }

    return (
        <div style={{
            width: '100%',
            height: '100%',
            position: 'absolute',
            top: 0,
            left: 0,
        }}>
            <DockableContainer 
                isDocked={false}
                position='bottom'
                onClickOutside={onClickOutside}
            >
                {renderBody()}
            </DockableContainer>
        </div>
    );
}