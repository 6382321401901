import { OAUTH2_TOKEN_ERROR_TYPE } from "@kalyzee/kast-app-module";

const INVALID_REQUEST_MESSAGE = 'invalid request';
const UNAUTHORIZED_MESSAGE = 'unautorized';
const SERVER_ERROR = 'server error';
const AUTHORIZATION_PENDING = 'authorization pending';
const TIME_IS_UP = 'time is up';

export const TokenErrorToMessage: { [key in OAUTH2_TOKEN_ERROR_TYPE]: string } = {
    [OAUTH2_TOKEN_ERROR_TYPE.INVALID_REQUEST]: INVALID_REQUEST_MESSAGE,
    [OAUTH2_TOKEN_ERROR_TYPE.INVALID_CLIENT]: INVALID_REQUEST_MESSAGE,
    [OAUTH2_TOKEN_ERROR_TYPE.INVALID_GRANT]: INVALID_REQUEST_MESSAGE,
    [OAUTH2_TOKEN_ERROR_TYPE.INVALID_SCOPE]: INVALID_REQUEST_MESSAGE,
    [OAUTH2_TOKEN_ERROR_TYPE.UNAUTHORIZED_CLIENT]: UNAUTHORIZED_MESSAGE,
    [OAUTH2_TOKEN_ERROR_TYPE.UNSUPPORTED_GRANT_TYPE]: INVALID_REQUEST_MESSAGE,
    [OAUTH2_TOKEN_ERROR_TYPE.SERVER_ERROR]: SERVER_ERROR,
    [OAUTH2_TOKEN_ERROR_TYPE.AUTHORIZATION_PENDING]: AUTHORIZATION_PENDING,
    [OAUTH2_TOKEN_ERROR_TYPE.SLOW_DOWN]: INVALID_REQUEST_MESSAGE, // should not be displayed
    [OAUTH2_TOKEN_ERROR_TYPE.ACCESS_DENIED]: UNAUTHORIZED_MESSAGE,
    [OAUTH2_TOKEN_ERROR_TYPE.EXPIRED_TOKEN]: TIME_IS_UP,
}
