import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import authReducer from '../features/main/mainSlice';
import filesReducer from '../features/files/filesSlice';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    files: filesReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
