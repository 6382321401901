import { ErrorResponse } from "@kalyzee/kast-app-module";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { useRef, useState } from "react";
import { RootState } from "../../app/store";
import { logout } from "../../features/main/extraActions";
import { logger } from "./logging";
import { ThunkInput } from "./types";

const shouldLogout = (error: ErrorResponse) => {
    if (!error.status) return false;
    const breakingCodes = [403];
    return breakingCodes.indexOf(error.status) > -1;
}

interface ThunkApi {
    getState: () => RootState,
    dispatch: (action: any) => void,
}

export const createAppAsyncThunk = <T, U> (
    actionName: string,
    requestCreator: (data: T, thunkApi: ThunkApi) => Promise<U>,
) => {
    return createAsyncThunk(
        actionName,
        async (input: ThunkInput<T, U>, thunkApi) => {
            const { dispatch, rejectWithValue, getState } = thunkApi
            try {
                const response: U = await requestCreator(
                    input.data, 
                    { 
                        getState: getState as () => RootState,
                        dispatch,
                    }
                );
                input.onSuccess?.(response);
                return response;
            } catch (error) {
                if (shouldLogout(error as ErrorResponse)) {
                    logger.error(`breaking error while making a request -> logout`);
                    dispatch(logout());
                }
                return rejectWithValue(error);
            }
        }
    )
}

export const useAsyncReference = <T>(value?: T) => {
    const ref = useRef<T | null>(value || null);
    const [, forceRender] = useState(false);

    function updateState(newState: T | null) {
        ref.current = newState;
        forceRender(s => !s);
    }

    return [ref, updateState] as const;
};
  
export const mergeStyles = (
    style: React.CSSProperties, 
    extraStyle?: React.CSSProperties,
): React.CSSProperties => ({ ...style, ...(extraStyle || {}) });

export const delay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

export const awaitWithMinimumDelay = <T = any>(promise: Promise<T>, ms: number): Promise<T> => {
    const startsAt = new Date();
    const waitDelay = async (): Promise<void> => {
      const endsAt = new Date();
      const diff = endsAt.getTime() - startsAt.getTime();
      if (diff >= ms) return;
      await delay(ms - diff);
    };
    const result = new Promise<T>((resolve, reject) => {
      promise
        .then(async (value: T) => {
          await waitDelay();
          resolve(value);
        })
        .catch(async (error: any) => {
          await waitDelay();
          reject(error);
        });
    });
    return result;
  };