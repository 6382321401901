import { CssFunction } from "../helpers/types"
import { mergeStyles } from "../helpers/utils"

export const makeOverlayContainerSectionStyle: CssFunction = (extraStyle) => mergeStyles({
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    backgroundColor: '#e3e2fe',
    boxSizing: 'border-box',
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    borderBottomRightRadius: 10,
    borderBottomLeftRadius: 10,
    // eslint-disable-next-line no-multi-str
    boxShadow: '0px 7px 14px rgba(0, 0, 0, 0.05), \
        0px 0px 3.12708px rgba(0, 0, 0, 0.0798), \
        0px 0px 0.931014px rgba(0, 0, 0, 0.1702)',
}, extraStyle)

export const makeMainContainerRowStyle: CssFunction = (extraStyle) => mergeStyles({
    position: 'absolute',
    top: '0',
    left: '0',
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    zIndex: 10000,
}, extraStyle)

export const makeMainContainerColStyle: CssFunction = (extraStyle) => mergeStyles({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
}, extraStyle)

export const makeBlurStyle: CssFunction = () => ({
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backdropFilter: 'blur(2px)',
    zIndex: 100,
})