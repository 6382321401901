const log = (msg: string) => {
    window.parent.postMessage({
        "type": "logs",
        "data": msg
    }, "*");
    console.log(msg);
};

const formatMessage = (message: string, tag: string) => 
    `[${new Date().toISOString()}|${tag}]: ${message}`;

export const logger = {
    log: (message: string) => log(formatMessage(message, 'LOG')),
    info: (message: string) => log(formatMessage(message, 'INFO')),
    error: (message: string) => log(formatMessage(message, 'ERROR')),
    debug: (message: string) => log(formatMessage(message, 'DEBUG')),
}