import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
    };
  }

export function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        function handleResize() {
        setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowDimensions;
}

export function useOutsideClickRef(onClick: () => void) {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (ref.current && !ref.current.contains(event.target as any)) {
        onClick();
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, onClick]);
  
  return ref;
}

// https://github.com/facebook/react/issues/14490#issuecomment-451924162
const SENTINEL = {};
export function useRefFn<T>(init: () => T) {
    const ref = useRef<T | typeof SENTINEL>(SENTINEL);
    if (ref.current === SENTINEL) {
        ref.current = init();
    }
    return ref as React.MutableRefObject<T>;
}

export interface AdminPinAPI {
  requestPermission?: () => Promise<boolean>,
  pinAttempt: string,
  setPinAttempt: (value: string) => void,
  pinError: string,
}

export function useAdminPin(
  pin: string | undefined, 
  onRequestPermission: () => void,
  onSuccess: () => void, 
): AdminPinAPI {
  const [pinAttempt, _setPinAttempt] = useState('');
  const resolvePinValidation = useRef<(valid: boolean) => void>();
  const [pinError, setPinError] = useState('');

  const { t } = useTranslation();

  useEffect(() => {
    if (!pin) return;

    if (pinAttempt.length !== pin.length) {
        if (pinError) setPinError('');
        return
    };

    if (pin === pinAttempt) {
      if (resolvePinValidation.current) {
        resolvePinValidation.current(true);
        resolvePinValidation.current = undefined;
        _setPinAttempt('');
        onSuccess();
      }
    } else {
      setPinError(t('wrong_pin'));
    }
  }, [pin, pinAttempt, _setPinAttempt, setPinError, pinError, onSuccess, t]);

  const requestPermission = async (): Promise<boolean> => {
    _setPinAttempt('');
    onRequestPermission();
    const promise = new Promise<boolean>((resolve) => {
        resolvePinValidation.current = resolve;
    });
    const pinValidation = await promise;
    return pinValidation;
  }

  const setPinAttempt = (value: string) => {
    const digits = new Set(['0', '1', '2', '3', '4', '5', '6', '7', '8', '9']);
    const cleanedPin = value
        .split('')
        .filter((c) => digits.has(c))
        .slice(0, pin?.length ?? 4)
        .join('');
    _setPinAttempt(cleanedPin);
  }

  return {
    requestPermission: pin ? requestPermission : undefined,
    pinAttempt,
    setPinAttempt,
    pinError,
  }
}

export const useProgress = (endDate: Date | undefined, stepMs=50) => {
  const [progress, setProgress] = useState(0);
  const [totalTimeout, setTotalTimeout] = useState(0);

  const timeoutMs = (expiration: Date) => {
      return Math.max(new Date(expiration).valueOf() - new Date().valueOf(), 0);
  }

  useEffect(() => {
      if (endDate) {
          setTotalTimeout(timeoutMs(endDate));
          setProgress(0);
      }
  }, [endDate, setTotalTimeout, setProgress]);

  useEffect(() => {
      if (!endDate) return;

      const progressTimer = setInterval(() => {
          const timeout = timeoutMs(endDate);
          setProgress((totalTimeout - timeout) / totalTimeout);
      }, stepMs);

      return () => clearInterval(progressTimer);
  }, [totalTimeout, endDate, stepMs]);

  return {
      progress,
  };
}
