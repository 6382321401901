import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { selectExternalAuth, selectToast, selectToken, setTokens } from "../../features/main/mainSlice";

import containersStyles from '../../common/css/containers.module.css';
import styles from './GlobalWrapper.module.css';
import { Outlet } from "react-router-dom";
import { DeviceCode } from "../components/DeviceCode";
import client from "../helpers/api";
import { getOAuth2ClientId } from "../helpers/env";
import { useTranslation } from "react-i18next";
import { Toast } from "../components/Toast";

export const GlobalWrapper = () => {
    const token = useAppSelector(selectToken);

    const externalAuth = useAppSelector(selectExternalAuth);
    
    const dispatch = useAppDispatch();

    const { t } = useTranslation();

    const renderOfflineDialog = () => {
        if (externalAuth) {
            return (
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    backgroundColor: '#253144dd',
                    padding: 30,
                    borderRadius: 10,
                    textAlign: 'center',
                    color: 'white',
                    fontSize: '1.5em',
                }}>{t('the_whiteboard_is_not_connected')}</div>
            );
        }
        return (
            <DeviceCode 
                client={client} 
                clientId={getOAuth2ClientId() || ''} 
                onTokens={(tokens) => dispatch(setTokens(tokens))}
            />
        )
    }

    const renderOfflineContainer = () => {
        return (
            <div style={{ width: '100vw', height: '100vh' }}>
                <div className={styles.blurredBackground} />
                <div className={styles.backgroundOverride}>
                    <div className={containersStyles.mainContainer}>
                        <div className={containersStyles.formContainer}>
                            {renderOfflineDialog()}
                        </div> 
                    </div>
                </div>
            </div>
        );
    }

    const renderOnlineContainer = () => {
        return (
            <div className={containersStyles.mainContainer}>
                <Outlet />
                <Toast />
            </div>
        )
    }

    return token ? renderOnlineContainer() : renderOfflineContainer();
}