import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { Parameter, removeParameter } from "../../common/helpers/parameters";

/**
 * logout and reset actions are being isolated in separated files to avoid the following circular dependency :
 * - all slices are depending on the common/utils file because they use the createAsyncAppThunk
 * - the createAsyncAppThunk depends on the authSlice to be abble to dispatch a logout() in case of 403 error code
 * - the logout thunk then depends on all the slices to get their reset actions, there is a circular dependency
 * => the logout and all the reset actions were isolated from the slices
 */

const resetAuth = createAction('main/reset');

const logout = createAsyncThunk(
    'main/logout',
    async (foo, { dispatch }) => {
        removeParameter(Parameter.Token);
        removeParameter(Parameter.RefreshToken);
        dispatch(resetAuth());
    }
);
 
export {
    resetAuth,
    logout,
}