import { CSSProperties, PropsWithChildren, useState } from "react";

interface CustomButtonProps {
    onClick: () => void,
    disabled?: boolean,
    style?: CSSProperties,
    nativeKey?: string,
    color?: string,
    hoverColor?: string,
    disabledColor?: string,
}

export const CustomButton = ({
    onClick,
    disabled,
    style,
    nativeKey,
    color,
    hoverColor,
    disabledColor,
    children,
}: PropsWithChildren<CustomButtonProps>) => {
    const [mouseHovering, setMouseHovering] = useState(false);

    const handleMouseEnter= () => setMouseHovering(true);
    const handleMouseLeave = () => setMouseHovering(false);

    const _color = color ?? '#5957d1';
    const _hoverColor = hoverColor ?? '#4e4db3';
    const _disabledColor = disabledColor ?? '#a5a5a580'

    return (
        <button 
            key={nativeKey}
            style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                height: 33,
                flexShrink: 0,
                backgroundColor: disabled ? _disabledColor : (mouseHovering ? _hoverColor : _color),
                border: 'none',
                color: 'white',
                borderRadius: 4,
                paddingLeft: 10,
                paddingRight: 10,
                paddingTop: 5,
                paddingBottom: 5,
                fontSize: '1em',
                cursor: disabled ? 'default' : 'pointer',
                ...(style ?? {}),
            }}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onClick={() => disabled || onClick()}
        >{children}</button>
    )
}

CustomButton.defaultProps = {
    disabled: false,
    color: undefined,
    hoverColor: undefined,
    disabledColor: undefined,
}

export default CustomButton;
