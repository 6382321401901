import { Tokens, CompatibilityInfo } from '@kalyzee/kast-app-module';
import { createAppAsyncThunk } from '../../common/helpers/utils';

import client, { socket } from '../../common/helpers/api';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { logout, resetAuth } from './extraActions';
import { RootState } from '../../app/store';
import { getParameter, getParameterFromSources, Parameter, ParameterSource, saveParameter } from '../../common/helpers/parameters';
import { logger } from '../../common/helpers/logging';

export enum ToastType {
  Info,
  Error,
  Success,
}

export interface ToastState {
  message?: string,
  position?: 'center' | 'bottom',
  type?: ToastType,
  progressBar?: boolean,
}

export interface MainState {
  status: 'idle' | 'loading' | 'failed',
  externalAuth: boolean,
  externalWhiteboard: boolean,
  autoJoin: boolean,
  defaultId: string,
  defaultName: string,
  token?: string,
  refreshToken?: string,
  error?: any,
  appVersion?: string,
  adminPin?: string,
  toast?: ToastState,
  transparentBackgroundEnabled?: boolean,
  compatibilityInfo?: {
    apiVersion?: string,
    isSupported: boolean,
  },
}

export const tokenRefresh = createAppAsyncThunk(
  'main/tokenRefresh',
  async (_, { getState, dispatch }) => {
    const state = getState();
    const refreshToken = state.auth.refreshToken;
    if (!refreshToken) {
      logger.error(`no refresh_token while refreshing -> logout`);
      dispatch(logout());
      return undefined;
    }
    try {
      const response = await client.user.tokenRefresh({ refreshToken }); 
      return response.data;
    } catch {
      logger.error(`error while refreshing -> logout`);
      dispatch(logout());
    }
  }
);

export const getCompatibilityInfo = createAppAsyncThunk(
  'main/getCompatibilityInfo',
  async () => {
    const httpCompatInfo = await client.getCompatibilityInfo();
    let apiVersion: string | undefined;
    let isSupported = false;
    if (httpCompatInfo) {
      apiVersion = httpCompatInfo.apiVersion;
      const websocketCompatInfo = await socket.getCompatibilityInfo(httpCompatInfo.apiVersion, 'version');
      if (websocketCompatInfo) {
        isSupported = httpCompatInfo.isSupported && websocketCompatInfo.isSupported;
      }
    }
    return {
      apiVersion,
      isSupported,
    } 
  }
);

export const downloadFile = createAppAsyncThunk(
  'main/downloadFile',
  async (id: string) => {
    const response = await client.file.download(id); 
    return response.data;
  }
)

const makeInitialState = (): MainState => {
  const tokenParameter = getParameter(Parameter.Token);
  const token = tokenParameter?.value;
  const externalAuth = tokenParameter?.source === ParameterSource.Url && !tokenParameter.isDefault;

  const refreshToken = getParameter(Parameter.RefreshToken)?.value;

  const autoJoin = getParameterFromSources(Parameter.AutoJoin)?.value === 'true';

  const idParameter = getParameter(Parameter.Id);
  const defaultId = idParameter?.value || '';
  const externalWhiteboard = idParameter?.source === ParameterSource.Url && !idParameter.isDefault;

  const defaultName = getParameter(Parameter.Name)?.value || 'user';

  const appVersion = process.env.REACT_APP_VERSION;

  const adminPin = getParameter(Parameter.AdminPin)?.value;

  const transparentBackgroundEnabled = getParameter(Parameter.TransparentBackgroundEnabled)?.value === 'true';

  return { 
    status: 'idle',
    externalAuth,
    externalWhiteboard,
    autoJoin: autoJoin || externalWhiteboard,
    defaultId,
    defaultName,
    token,
    refreshToken,
    error: undefined,
    appVersion,
    adminPin,
    toast: undefined,
    transparentBackgroundEnabled,
  }
};

const initialState: MainState = makeInitialState();

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setTokens: (state: MainState, action: PayloadAction<Tokens>) => {
      const isExternal = state.externalAuth;
      if (action.payload.token) {
        logger.info(`setting token`)
        state.token = action.payload.token;
        if (!isExternal) saveParameter(Parameter.Token, action.payload.token);
      }
      if (action.payload.refreshToken) {
        logger.info(`setting refresh_token`)
        state.refreshToken = action.payload.refreshToken;
        if (!isExternal) saveParameter(Parameter.RefreshToken, action.payload.refreshToken);
      }
    },
    setDefaults: (state: MainState, action: PayloadAction<{ defaultId?: string, defaultName?: string }>) => {
      const isExternal = state.externalWhiteboard;
      const payload = action.payload;
      if (payload.defaultId) {
        state.defaultId = payload.defaultId;
        if (!isExternal) saveParameter(Parameter.Id, payload.defaultId);
      };
      if (payload.defaultName) {
        state.defaultName = payload.defaultName;
        if (!isExternal) saveParameter(Parameter.Name, payload.defaultName);
      };
    },
    setToast: (state: MainState, action: PayloadAction<ToastState>) => {
      state.toast = action.payload;
    },
    resetToast: (state: MainState) => {
      state.toast = undefined;
    },
    setTransparentBackgroundEnabled: (state: MainState, action: PayloadAction<boolean>) => {
      state.transparentBackgroundEnabled = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(tokenRefresh.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(tokenRefresh.fulfilled, (state, { payload }) => {
        if (payload) { // If refresh thunk did not return undefined
          state.status = 'idle';
          state.error = undefined;
          state.token = payload.token;
          state.refreshToken = payload.refreshToken;
          if (payload.token) saveParameter(Parameter.Token, payload.token);
          if (payload.refreshToken) saveParameter(Parameter.RefreshToken, payload.refreshToken);
        }
      })
      .addCase(getCompatibilityInfo.fulfilled, (state, { payload }) => {
        state.compatibilityInfo = payload;
      })
      .addCase(resetAuth, makeInitialState)
  },
});

export const { setTokens, setDefaults, setToast, resetToast, setTransparentBackgroundEnabled } = authSlice.actions;

export const selectError = (state: RootState) => state.auth.error;
export const selectToken = (state: RootState) => state.auth.token;
export const selectRefreshToken = (state: RootState) => state.auth.refreshToken;
export const selectExternalAuth = (state: RootState) => state.auth.externalAuth;
export const selectExternalWhiteboard = (state: RootState) => state.auth.externalWhiteboard;
export const selectAutoJoin = (state: RootState) => state.auth.autoJoin;
export const selectDefaultId = (state: RootState) => state.auth.defaultId;
export const selectDefaultName = (state: RootState) => state.auth.defaultName;
export const selectAppVersion = (state: RootState) => state.auth.appVersion;
export const selectAdminPin = (state: RootState) => state.auth.adminPin;
export const selectToast = (state: RootState) => state.auth.toast;
export const selectTransparentBackgroundEnabled = (state: RootState) => state.auth.transparentBackgroundEnabled;
export const selectCompatibilityInfo = (state: RootState) => state.auth.compatibilityInfo;

export default authSlice.reducer;