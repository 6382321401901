import {
    CommunicationAdapterState,
    CommunicationAdapterStatus,
    KalyzeeCommunicationAdapter,
    UserSettings,
    Whiteboard,
    WhiteboardMenuCustomButton,
} from "@kalyzee/whiteboard-react";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { 
    getCompatibilityInfo,
    selectAdminPin,
    selectAppVersion,
    selectAutoJoin, 
    selectCompatibilityInfo, 
    selectDefaultId, 
    selectDefaultName, 
    selectExternalAuth, 
    selectExternalWhiteboard, 
    selectToken,
    selectTransparentBackgroundEnabled,
    setDefaults, 
    setToast, 
    setTransparentBackgroundEnabled, 
    ToastType, 
    tokenRefresh,
} from "../../features/main/mainSlice";
import { 
    ConnectionError, 
    FlexibleId,
    WebSocket,
    WhiteboardUserValidatedSettings,
} from "@kalyzee/kast-websocket-module";
import { WhiteboardImperativeAPI } from "@kalyzee/whiteboard-react";
import { useAdminPin, useWindowDimensions } from "../helpers/hooks";
import Overlay, { OverlayMode } from "./Overlay";
import client, { getSocketTokenGenerateUrl, getUserSocketUrl, socket } from "../helpers/api";
import { useTranslation } from "react-i18next";
import { logger } from "../helpers/logging";
import { getParameter, Parameter, saveParameter } from "../helpers/parameters";
import { getPostMessageBridge } from "../helpers/post-message-bridge";
import { dataURLToFile } from "../helpers/files";
import { PostMessageAPI } from "../../packages/post-message-bridge/api";

import { ReactComponent as BugSvg } from '../../assets/svg/bug.svg';
import { ReactComponent as LogoutSvg } from '../../assets/svg/logout.svg';
import { ReactComponent as ShuffleSvg } from '../../assets/svg/shuffle.svg';
import { ReactComponent as BackArrowSvg } from '../../assets/svg/back-arrow.svg';

import { BugReporter } from "./BugReporter";

  
export const WhiteboardWrapper = () => {
    const dispatch = useAppDispatch();

    // query params :

    const defaultId = useAppSelector(selectDefaultId);
    const defaultName = useAppSelector(selectDefaultName);
    const autoJoin = useAppSelector(selectAutoJoin);
    const token = useAppSelector(selectToken);
    const externalAuth = useAppSelector(selectExternalAuth);
    const externalMeeting = useAppSelector(selectExternalWhiteboard);
    const adminPin = useAppSelector(selectAdminPin);
    const transparentBackgroundEnabled = useAppSelector(selectTransparentBackgroundEnabled);

    // component state :

    const [tempId, setTempId] = useState('')
    const [tempName, setTempName] = useState('');
    const [adapterState, setAdapterState] = useState<CommunicationAdapterState>({ status: CommunicationAdapterStatus.Connecting, isLoading: false });
    const [joinErrorMessage, setJoinErrorMessage] = useState('');
    const [displayOverlay, setDisplayOverlay] = useState(false);
    const [displayBugReporter, setDisplayBugReporter] = useState(false);
    const [overlayMode, setOverlayMode] = useState(OverlayMode.Default);

    const appVersion = useAppSelector(selectAppVersion);
    const compatibilityInfo = useAppSelector(selectCompatibilityInfo);

    const [socketAdapter, setSocketAdapter] = useState<KalyzeeCommunicationAdapter>();

    const whiteboardAPI = useRef<WhiteboardImperativeAPI>(null);

    const { width } = useWindowDimensions();
    const isMobile = () => width < 600;

    const { t, i18n } = useTranslation();

    const { 
        requestPermission, 
        pinAttempt, 
        setPinAttempt, 
        pinError,
    } = useAdminPin(
        adminPin, 
        () => setOverlayMode(OverlayMode.Pin),
        () => setOverlayMode(OverlayMode.Default), 
    );

    /////////////////////////////////////////////
    ////////////////// HELPERS //////////////////
    /////////////////////////////////////////////

    const handleSocketConnectionError = (error: ConnectionError) => {
        if (error === ConnectionError.TOKEN_EXPIRED) {
            dispatch(tokenRefresh({ data: undefined }));
        }
    }

    const connectSocket = async () => {
        if (token) {
            logger.info(`attempting socket connection`);
            try {
                await socket?.connect(
                    token, 
                    getSocketTokenGenerateUrl(),
                );   
            } catch {
                logger.error(`error while connecting socket, should be handled in onConnectionFailed`);
                // Error is handled in onConnectionError
            }
        }
    }

    ///////////////////////////////////////////////
    ///////////// POST MESSAGE BRIDGE /////////////
    ///////////////////////////////////////////////

    useEffect(() => {
        const postMessageBridge = getPostMessageBridge();
        if (!postMessageBridge) {
            return;
        }
        const onInsertImage = (
            data: PostMessageAPI['out']['actions']['insert-image']['requestData'],
        ) => {
            whiteboardAPI.current?.insertImage(
                dataURLToFile(data.dataURL),
                data.insertOnCanvasDirectly,
            );
        }
        const onSetTransparentBackground = (
            data: PostMessageAPI['out']['actions']['set-transparent-background-enabled']['requestData'],
        ) => {
            dispatch(setTransparentBackgroundEnabled(data));
        }
        postMessageBridge.on('insert-image', onInsertImage);
        postMessageBridge.on('set-transparent-background-enabled', onSetTransparentBackground);
        return () => {
            postMessageBridge.off('insert-image', onInsertImage);
            postMessageBridge.off('set-transparent-background-enabled', onSetTransparentBackground);
        }
    }, []);

    ///////////////////////////////////////////////
    ////////////////// LIFE CYCLE /////////////////
    ///////////////////////////////////////////////

    useEffect(() => {
        dispatch(getCompatibilityInfo({ data: undefined }));
        const beforeUnload = () => {
            whiteboardAPI.current?.syncAll();
        }
        window.addEventListener('beforeunload', beforeUnload);
        return () => window.removeEventListener('beforeunload', beforeUnload);
    }, []);

    useEffect(() => {
        const adapter = new KalyzeeCommunicationAdapter(
            client, 
            socket, 
            {
                onConnectionError: handleSocketConnectionError,
            },
        );
        adapter.onAdapterState((status) => {
            setAdapterState(status);
        });
        setSocketAdapter(adapter);
        return () => socket.removeAllEventListeners();
    }, []);

    useEffect(() => {
        connectSocket();
    }, [token]);

    useEffect(() => {
        if (adapterState.status === CommunicationAdapterStatus.Connected && !adapterState.isLoading) {
            const currentInfo = whiteboardAPI.current?.getCurrentInfo();
            if (currentInfo) {
                // Socket went offline and is back online, join again automatically
                handleJoin(currentInfo.id, defaultName, true);
            } else if (autoJoin) {
                // No meeting was joined but autojoin param was used
                handleJoin(defaultId, defaultName);
            }
        }
    }, [adapterState]);

    const setParametersAsDefault = (joinedId: string, joinedName: string) => {
        // Set as default the successful id / name :
        dispatch(setDefaults({ defaultId: joinedId, defaultName: joinedName }));
        // Clear the temp :
        if (tempId) setTempId('');
        if (tempName) setTempName('');
    }

    const handleValidatedSettings = (validatedSettings: WhiteboardUserValidatedSettings | undefined) => {
        if (!validatedSettings) return;
        const { settings, errors } = validatedSettings;
        if (settings) {
            saveParameter(Parameter.IsMaster, `${settings.isMaster}`);
            saveParameter(Parameter.FollowMasters, `${settings.followMasters}`);
        } 
        if (errors?.length) {
            dispatch(setToast({ message: t(errors[0]), type: ToastType.Error, progressBar: true }));
        }
    }

    const handleJoin = async (toJoinId: string, toJoinName: string, auto=false) => {
        const error = (message: string) => {
            setJoinErrorMessage(message);
        }

        if (!toJoinId) {
            error(t('the_id_is_empty'));
            return;
        }

        if (displayOverlay) setDisplayOverlay(false);
        if (joinErrorMessage) {
            setJoinErrorMessage('');
        }

        if (!auto) {
            // Already joined a whiteboard and trying to re-join manually
            if (whiteboardAPI.current?.getCurrentInfo()?.shortId === toJoinId) {
                return; // Same activity, nothing to do
            }
        }

        if (
            adapterState?.status !== CommunicationAdapterStatus.Connected 
            && adapterState?.status !== CommunicationAdapterStatus.Joined) {
            return; // We're not in a proper state to join
        }

        const idParam: FlexibleId = { type: auto ? 'normal' : 'short', value: toJoinId };

        // building settings :
        const isInvisible = getParameter(Parameter.Invisible)?.value === 'true';
        const isMaster = getParameter(Parameter.IsMaster)?.value === 'true';
        const followMasters = getParameter(Parameter.FollowMasters)?.value === 'true';

        const settingsArray: Partial<UserSettings>[] = [{
            username: toJoinName,
            isInvisible,
            isMaster: isMaster && !isInvisible,
            followMasters: followMasters && !(isMaster && !isInvisible),
        }];

        try {
            const joinRes = await whiteboardAPI.current?.join( 
                idParam,
                settingsArray,
            );
            if (joinRes?.error) {
                error(joinRes.error.message);
                return;
            }
            if (!auto) setParametersAsDefault(toJoinId, toJoinName);
        } catch (err: any) {
            error(err);
        }
    }

    const getVersionsSeenAt = () => {
        try {
            const seenAtParam = getParameter(Parameter.VersionsSeenAt);
            if (seenAtParam) {
                const seenAt = JSON.parse(seenAtParam.value);
                if (seenAt instanceof Object) {
                    return seenAt;
                }
            }
        } catch {}
        return undefined;
    }

    const getVersionIsOldAfterDelay = () => {
        const delayParam = getParameter(Parameter.VersionIsOldAfterDelay);
        if (delayParam) {
            const delay = parseInt(delayParam.value);
            if (!isNaN(delay)) {
                return delay;
            }
        }
        return undefined;
    }

    const getShowNewVersionsOnFirstUse = () => {
        const showParam = getParameter(Parameter.ShowNewVersionsOnFirstUse);
        if (showParam) {
            return showParam.value === 'true';
        }
        return false;
    }

    const saveFeaturesSeenAt = (seenAt: object) => {
        saveParameter(Parameter.VersionsSeenAt, JSON.stringify(seenAt));
    }

    //////////////////////////////////////////////
    ////////////////// RENDERING /////////////////
    //////////////////////////////////////////////

    const getMenuButtons = (): WhiteboardMenuCustomButton[] | undefined => {
        const buttons: WhiteboardMenuCustomButton[] = [];
        if (!externalAuth) buttons.push({
            content: renderMenuButton('logout', <LogoutSvg />),
            onClick: () => setOverlayMode(OverlayMode.Logout),
        })
        if (!externalMeeting) buttons.push({
            content: renderMenuButton('switch_whiteboard', <ShuffleSvg />),
            onClick: () => {
                setDisplayOverlay(true);
                setOverlayMode(OverlayMode.Default);
            },
        })
        if (!externalMeeting) buttons.push({
            content: renderMenuButton('leave_whiteboard', <BackArrowSvg />),
            onClick: async () => {
                if (requestPermission) {
                    const permissionGranted = await requestPermission();
                    if (!permissionGranted) return;
                }
                whiteboardAPI.current?.leave();
            },
        })
        buttons.push({
            content: renderMenuButton('report_a_bug', <BugSvg />, true),
            onClick: () => setDisplayBugReporter(true),
            versionTag: 'standalone_1.12.1'
        });
        return buttons.length ? buttons : undefined;
    }

    const renderMenuButton = (name: string, icon: JSX.Element, isNew=false) => {
        return (
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center'
            }}>
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                }}>
                    {icon}
                    <div style={{
                        marginLeft: '0.5em',
                    }}>{t(name)}</div>
                </div>
            </div>
        );
    };

    const renderHelpHeader = (): JSX.Element => {
        return (
            <div>
                <div>App Version : {appVersion}</div>
                <div style={{
                    color: compatibilityInfo?.isSupported ? '' : 'red',
                }}>Backend Version : {compatibilityInfo?.apiVersion}</div>
            </div>
        );
    };
    
    const renderWhiteboard = () => {
        return (
            <Whiteboard 
                ref={whiteboardAPI}
                communicationAdapter={socketAdapter}
                onValidatedUserSettings={handleValidatedSettings}
                onRequestPermission={requestPermission}
                customMenuButtons={getMenuButtons()}
                renderHelpHeader={renderHelpHeader}
                easyAccessEnabled
                langCode={i18n.language}
                transparentBackgroundEnabled={transparentBackgroundEnabled}
                versionsOptions={{
                    isOldAfterDelay: getVersionIsOldAfterDelay(),
                    seenAt: getVersionsSeenAt(),
                    onSeenAt: saveFeaturesSeenAt,
                    showNewVersionsOnFirstUse: getShowNewVersionsOnFirstUse(),
                }}
            />
        );
    }

    const renderOverlay = () => {
        return (
            <Overlay
                adapterState={adapterState}
                defaultId={defaultId}
                defaultName={defaultName}
                tempId={tempId}
                tempName={tempName}
                setTempId={setTempId}
                setTempName={setTempName}
                onConfirm={() => handleJoin(tempId, tempName)}
                pin={pinAttempt}
                setPin={setPinAttempt}
                targetPinSize={adminPin?.length ?? 0}
                pinError={pinError}
                onRequestPermission={requestPermission}
                overlayMode={overlayMode}
                setOverlayMode={setOverlayMode}
                forceDisplay={displayOverlay}
                setForceDisplay={setDisplayOverlay}
                retryConnection={connectSocket}
                isMobile={isMobile()}
                joinErrorMessage={joinErrorMessage}
                // setJoinErrorMessage={setJoinErrorMessage}
            />
        );
    }

    const renderBugReporter = () => {
        return (
            <BugReporter 
                display={displayBugReporter} 
                setDisplay={setDisplayBugReporter} 
                getExtraData={() => {
                    if (!whiteboardAPI.current) {
                        return 'whiteboardAPI not ready';
                    }
                    const data = whiteboardAPI.current.getDebugData();
                    return JSON.stringify(whiteboardAPI.current.getDebugData());
                }}
            />
        );
    }
    
    return (
        <div style={{
            position: 'relative',
            height: '100%',
            width: '100%',
        }}>
            {renderWhiteboard()}
            {renderOverlay()}
            {renderBugReporter()}
        </div>
    )
}