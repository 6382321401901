import { CSSProperties, useEffect, useRef, useState } from "react";

export interface CustomTextAreaProps {
    defaultValue?: string,
    placeholder?: string,
    onChange?: (value: string) => void,
    maxLength?: number,
    autoFocus?: boolean,
    containerStyle?: CSSProperties,
    textAreaStyle?: CSSProperties,
    counterStyle?: CSSProperties,
}

export function CustomTextArea({
    defaultValue,
    placeholder,
    onChange,
    maxLength,
    autoFocus,
    containerStyle,
    textAreaStyle,
    counterStyle,
}: CustomTextAreaProps) {
    const clean = (input: string) => {
        if (!maxLength) {
            return input.slice();
        }
        return input.slice(0, maxLength);
    }

    const ref = useRef<HTMLTextAreaElement>(null);
    const [value, setValue] = useState(defaultValue ? clean(defaultValue) : '');

    useEffect(() => {
        if (autoFocus) {
            ref.current?.focus();
        }
    }, [autoFocus]);

    const _onChange = (newMessage: string) => {
        const cleanedMessage = clean(newMessage);
        setValue(cleanedMessage);
        onChange?.(cleanedMessage);
    }

    return (
        <div style={{
            width: '100%', 
            height: '100%',
            ...(containerStyle ?? {}),
        }}>
            <textarea 
                ref={ref}
                value={value}
                onChange={(event) => _onChange(event.target.value)}
                placeholder={placeholder}
                style={{ 
                    resize: 'none', 
                    width: '100%', 
                    height: '100%',
                    borderStyle: 'none',
                    borderRadius: 6,
                    boxSizing: 'border-box',
                    ...(textAreaStyle ?? {}),
                }} 
            />
            {maxLength && (
                <div style={{
                    color: 'gray',
                    fontSize: '0.75em',
                    ...(counterStyle ?? {}),
                }}>{value.length}/{maxLength}</div>
            )}
        </div>
    )
}