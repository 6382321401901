import './App.css';
import { createBrowserRouter, createRoutesFromElements, Navigate, Route, RouterProvider } from 'react-router-dom';
import { GlobalWrapper } from './common/pages/GlobalWrapper';
import { WhiteboardPage } from './pages/WhiteboardPage';
import { useEffect } from 'react';

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<GlobalWrapper />} errorElement={<Navigate to='' />}>
      <Route path='' element={<WhiteboardPage />} />
    </Route>
  )
);

function App() {
  return <RouterProvider router={router} />
}

export default App;
